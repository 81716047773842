import './App.css';
import './styles/style.css';

import { useEffect } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import ReactGA from 'react-ga4';

//import CarsScreen from './pages/Cars.page.js';
import Cars from './pages/Cars2.page.js';
import Home from './pages/Home.page.js';
//import StatsPage from './pages/Stats.page.js';

import AdsService from './services/Ads.service.js';
import Mention from './pages/Mention.page.js';

const theme = createTheme({
  palette: {
    primary: {
      main: '#010101',
      dark: '#A29415',
      primary: "#387bbe",
      secondary: '#E26D5C',
      blue: '#06387e', //#03224C
      red: '#aa002b', //#5D0001
      light: '#E8EEF2',
      yellow: '#E0CA3C',
      cyan: '#048A81',
      grey: '#AFAFAF',
      white: '#F1F1F1',
      black: '#1f1f1f',
    },
  },
  typography: {
    fontFamily: [
      'Inter',
    ].join(','),
  },
});

const TRACKING_ID = "G-RTPCJXZV09"; 
ReactGA.initialize(TRACKING_ID);

function App() {

  useEffect(() => {
    // ReactGA.pageview(window.location.pathname + window.location.search);
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search, title: "Page Selected" });
  }, []);

  // Add Google Adsense script
	AdsService.useScript("https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-2785110663009665")

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />}>
          </Route>
          <Route path="cars" element={<Cars />} />
          {/* <Route path="cars2" element={<Cars />} /> */}
          {/* <Route path='stats' element={<StatsPage />} /> */}
          <Route path="mentions" element={<Mention />} />
          <Route path="*" element={<h1>Not Found</h1>} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
