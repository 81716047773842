import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import { Button, Drawer, Box, CircularProgress, Select, MenuItem } from '@mui/material';
import { Link } from 'react-router-dom';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import {Helmet} from "react-helmet";

import CarService from '../services/Car.service.js';
// import useAnalyticsEventTracker from '../services/Analytics.service.js';
import sendEvent from '../services/Analytics.service.js';

import CarCard from '../components/carCard.component.js';
import FilterComponent from '../components/filters2.component.js';
import Footer from '../components/footer.component.js';

import "../styles/cars.style.css";
import Ad from '../components/ad.component.js';
import CarModal2 from '../components/carModal2.component.js';

export default function CarPage() {
	const [allCars, setAllCars] = useState([]);
	const [cars, setCars] = useState([]);
	const [selectedCar, setSelectedCar] = useState(null);
    const [sort, setSort] = React.useState('date-desc');
	const [openModal, setOpenModal] = React.useState(false);
    const [largePeriod, setLargePeriod] = React.useState(false);
  	const [openFilter, setOpenFilter] = React.useState(false);
	
	const handleOpen = (car) => {
		setSelectedCar(car); 
		setOpenModal(true);
		sendEvent('open modal', 'click', car._id);
	};
	const handleClose = () => setOpenModal(false);

	const [offersInfos, setOffersInfos] = useState({"24h": 0, "7d": 0, "8h": 0, "total": 0, "1m": 0});

	useEffect(() => {

		(async () => {
			if (!largePeriod) {
				const params = new URLSearchParams(window.location.search);
				if (params.has("id")) {
					var id = params.get("id");
					let car = await CarService.fetchCars({_id: id}).then((data) => {return data[0]});
					if (car !== undefined) {
						handleOpen(car);
						console.log(car);
					}
				}
			}

			// Get dates
			const currentDate = new Date();
			const pastDate = new Date();
			pastDate.setMonth(currentDate.getMonth() - 1);

			// Convert dates to timestamps
			const currentTimeStamp = Math.floor(currentDate.getTime() / 1000);
			const pastTimeStamp = Math.floor(pastDate.getTime() / 1000);

			var temp = await CarService.fetchCars(largePeriod ? undefined : { "created_at": { "$gte": pastTimeStamp, "$lte": currentTimeStamp} });
			setAllCars(temp);
			setOffersInfos(CarService.calculateOfferInfos(temp));
		})();

	}, [largePeriod]);

    
    const handleSortChange = (event) => {
        setSort(event.target.value);
    };

	const toggleDrawer = (newOpen) => () => {
		setOpenFilter(newOpen);
	};

	const loading_component = () => {
		return (
		<Box sx={{ display: 'flex', justifyContent: 'space-around', height: "100%" }}>
			<Box style={{display: 'flex', flexDirection: "column", justifyContent: "center"}}>
				<CircularProgress style={{color: "#010101"}} />
				{/* <Typography>Loading Cars...</Typography> */}
			</Box>			
		</Box>
		)
	}

  	const showCars = (cars) => {
		return (
        <Box className="cars-cards-container">
            {
                cars.reduce((acc, car, index) => {
                    // Ajoute la carte de la voiture
                    acc.push(<CarCard car={car} handleOpen={handleOpen} key={car._id} />);

                    // Après chaque groupe de 4 cartes de voitures, insérer une carte publicitaire
                    if ((index + 1) % 7 === 0) {
                        acc.push(<Box key={index} className="car-card-ad-container">
							<Ad adFormat='fluid' adSlot={"3807250938"} key={`ad-${index}`} dataAdLayoutKey={"-5o+dm+64-ib-5"} />
						</Box>);
                    }

                    return acc;
                }, [])
            }
        </Box>
		);
  	}
    
	return (
		<Box className="cars-container">
			{ selectedCar ? 
				<Helmet>
    				<title>Cars - Model 3 Finder</title>
					<meta name="twitter:card" content="summary_large_image" />
					<meta name="twitter:title" content={selectedCar.title + " " + selectedCar.model} />
					<meta name="twitter:site" content="@Tesla3_Finder" />
					<meta name="twitter:creator" content="@Tesla3_Finder" />
					<meta name="twitter:image" content={selectedCar.image} />
					<meta property="og:url" content={"https://tesla.gavazziadrien.fr/cars?id=" + selectedCar._id} />
					<meta property="og:title" content={selectedCar.title + " " + selectedCar.model} />
					<meta property="og:description" content={selectedCar.category_normalized + " " + selectedCar.motor} />
					<meta property="og:image" content={selectedCar.image} />
            	</Helmet>
					:
				<Helmet>
					<meta name="twitter:card" content="summary" />
					<meta name="twitter:site" content="@Tesla3_Finder" />
					<meta name="twitter:creator" content="@Tesla3_Finder" />
					<meta name="twitter:image" content={"https://i.ibb.co/PhP6jfy/Model3-57.jpg"} />
					<meta property="og:url" content={"https://tesla.gavazziadrien.fr/cars"} />
					<meta property="og:title" content={"Tesla Model 3"} />
					<meta property="og:description" content={"Selection des offres de Tesla Model 3 d'occasion en France"} />
					<meta property="og:image" content={"https://i.ibb.co/PhP6jfy/Model3-57.jpg"} />
				</Helmet>
			}
			<Box className="home-navbar">
				<Box>
					<Link to="/">
						<Box>
							Home
						</Box>
					</Link>
					<Link to="/cars">
						<Box>
							Cars
						</Box>
					</Link>
				</Box>
			</Box>
			<Box className="cars-img">
                <Box></Box>
                <Box className="cars-welcome">
                    <Box>
                        <Box>
                            <Box>
                                <Typography variant="h4">{offersInfos["24h"]}</Typography>
                                <Typography variant="caption">Offers found  within 24h</Typography>
                            </Box>
                            <Box>
                                <Typography variant="h4">{offersInfos["7d"]}</Typography>
                                <Typography variant="caption">Offers found  within 1 week</Typography>
                            </Box>
                            <Box>
                                <Typography variant="h4">{offersInfos["1m"]}</Typography>
                                <Typography variant="caption">Offers found  within 1 month</Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
			<Box className="cars-panel">
                <Box className="cars-filter">
                    <FilterComponent allCars={allCars} setCars={setCars} cars={cars} largePeriod={largePeriod} setLargePeriod={setLargePeriod} sort={sort} setSort={setSort} />
                </Box>
                <Box className="cars-cards">
                    <Box className="cars-cards-header">
                        <Box>
                            <Typography variant="subtitle2"><strong>{cars.length}</strong> offers found</Typography>  
							{ window.innerWidth < 1250 ?
								<Box>
									<Button onClick={toggleDrawer(true)} className='cars-filter-btn'><FilterAltIcon />Filter</Button>
								</Box>
								:
								null
							}
                        </Box>
                        <Box>
                            <Box>
                                <Typography variant="subtitle2">Sort by:</Typography>
                            </Box>
                            <Box>
                                <Select
                                    labelId="sort-label"
                                    id="sortSelect"
                                    value={sort}
                                    onChange={handleSortChange}
                                    >
                                    <MenuItem value="price-asc"><Typography variant='caption'>Price (ascending)</Typography></MenuItem>
                                    <MenuItem value="price-desc"><Typography variant='caption'>Price (descending)</Typography></MenuItem>
                                    <MenuItem value="date-asc"><Typography variant='caption'>Creation date (oldest first)</Typography></MenuItem>
                                    <MenuItem value="date-desc"><Typography variant='caption'>Creation date (most recent first)</Typography></MenuItem>
                                </Select>
                            </Box>
                        </Box>
                    </Box>
    				{ cars.length > 0 ?  showCars(cars) : loading_component()}
                </Box>
			</Box>

			<Drawer open={openFilter} onClose={toggleDrawer(false)}>
					<FilterComponent allCars={allCars} setCars={setCars} cars={cars} largePeriod={largePeriod} setLargePeriod={setLargePeriod} sort={sort} setSort={setSort} btnClose={true} toggleDrawer={toggleDrawer}/>
			</Drawer>
            
	 		{/* <CarModal open={openModal} handleClose={handleClose} car={selectedCar} allCars={allCars}/> */}
	 		<CarModal2 open={openModal} handleClose={handleClose} car={selectedCar} allCars={allCars}/>
			<Footer />
		</Box>
	)
};