import React from 'react';
import Plot from 'react-plotly.js';

const colors = {
    "primary-color": "#387bbe",
    "secondary-color": "#E26D5C",
    "blue": "#06387e", /* #03224C, */
    "red": "#aa002b", /* #5D0001, */
    "light": "#E8EEF2",
    "yellow": "#E0CA3C",
    "cyan": "#048A81",
    "grey": "#AFAFAF",
    "white": "#F1F1F1",
    "black": "#1f1f1f",
    "bg-light": "#999999",
} 

export default class PlotService {
    static CarPricePlot = (car, cars, affineFunctionsParams) => {

        // const carCategory = car.category.includes("Grande autonomie") ? "Grande autonomie" : car.category.includes("Performance") ? "Performance" : "Standard plus";
        const carCategory = car.category_normalized;

        cars = cars.filter(d => d.category_normalized === carCategory);

        // Construire la ligne de référence à partir des paramètres de la fonction affine.
        const referenceLineX = [0, 200000]; // Remplacez avec la gamme de votre axe x si différente.
        const referenceLineY = referenceLineX.map(x => affineFunctionsParams.slope[carCategory] * x + affineFunctionsParams.intercept[carCategory]);

        const data = [
            {
                x: cars.map(d => d.km),
                y: cars.map(d => d.price),
                type: 'scatter',
                mode: 'markers',
                name: 'Offers',
                marker: { color: colors["light"] }
            },
            {
                x: referenceLineX,
                y: referenceLineY,
                type: 'scatter',
                mode: 'lines',
                name: 'Reference line',
                line: { color: colors["primary-color"] }
            },
            {
                x: [car.km],
                y: [car.price],
                type: 'scatter',
                mode: 'markers',
                name: 'Car selected',
                marker: { color: car.category.includes('Grande autonomie') ? colors["red"] : car.category.includes('Performance') ? colors["yellow"] : colors["blue"] }
            }
        ];

        const layout = {
            title: `Kilometre-based prices for ${carCategory}`,
            xaxis: {
                title: 'Kilometer (km)',
                range: [0, 200000],
                zeroline: false,
                showline: true,
            },
            yaxis: {
                title: 'Price (€)',
                range: [10000, 50000],
                zeroline: false,
                showline: true,
            },
            // width: 800,
            height: 300,
            margin: {
                l: 60,
                r: 20,
                b: 40,
                t: 40,
                pad: 4
            },
        };

        return <Plot data={data} layout={layout} />;
    };

    static CarPricePlotWithYear = (car, cars, affineFunctionsParams) => {
    
        // const carCategory = car.category.includes("Grande autonomie") ? "Grande autonomie" : car.category.includes("Performance") ? "Performance" : "Standard plus";
        const carCategory = car.category_normalized;

        cars = cars.filter(d => d.category_normalized === carCategory && d.year === car.year);

        const matchingParams = affineFunctionsParams.find(p => p.index === carCategory);

        // Construire la ligne de référence à partir des paramètres de la fonction affine pour l'année spécifique.
        const referenceLineX = [0, 200000]; // Remplacez avec la gamme de votre axe x si différente.
        const referenceLineY = referenceLineX.map(x => 
            matchingParams.intercept +
            matchingParams.coef_km * x + // correction ici: juste x au lieu de car.km * x
            matchingParams.coef_year * car.year
        );

        const data = [
            {
                x: cars.map(d => d.km),
                y: cars.map(d => d.price),
                type: 'scatter',
                mode: 'markers',
                name: 'Offers',
                marker: { color: colors["light"] }
            },
            {
                x: referenceLineX,
                y: referenceLineY,
                type: 'scatter',
                mode: 'lines',
                name: 'Reference line',
                line: { color: colors["primary-color"] }
            },
            {
                x: [car.km],
                y: [car.price],
                type: 'scatter',
                mode: 'markers',
                name: 'Car selected',
                marker: { color: car.category.includes('Grande autonomie') ? colors["red"] : car.category.includes('Performance') ? colors["yellow"] : colors["blue"] }
            }
        ];

        const layout = {
            title: `Kilometre-based prices for ${carCategory} in ${car.year}`,
            xaxis: {
                title: 'Kilometer (km)',
                range: [0, 200000],
                zeroline: false,
                showline: true,
            },
            yaxis: {
                title: 'Price (€)',
                range: [10000, 50000],
                zeroline: false,
                showline: true,
            },
            margin: {
                l: 60,
                r: 20,
                b: 40,
                t: 40,
                pad: 4
            },
        };

        return <Plot data={data} layout={layout} />;
    };

    static CarPricePlotWithYear2 = (car, cars, affineFunctionsParams) => {
    
        // const carCategory = car.category.includes("Grande autonomie") ? "Grande autonomie" : car.category.includes("Performance") ? "Performance" : "Standard plus";
        const carCategory = car.category_normalized;

        cars = cars.filter(d => d.category_normalized === carCategory && d.year === car.year);

        const matchingParams = affineFunctionsParams.find(p => p.index === carCategory);

        // Construire la ligne de référence à partir des paramètres de la fonction affine pour l'année spécifique.
        const referenceLineX = [0, 200000]; 
        const referenceLineY = referenceLineX.map(x => 
            matchingParams.intercept +
            matchingParams.coef_km * x + // correction ici: juste x au lieu de car.km * x
            matchingParams.coef_year * car.year
        );

        var cars_km = cars.map(d => d.km);
        var cars_prices = cars.map(d => d.price);

        const data = [
            {
                x: cars_km,
                y: cars_prices,
                type: 'scatter',
                mode: 'markers',
                name: 'Offers',
                marker: { 
                    color: colors["grey"],
                    size: 5 
                }
            },
            {
                x: referenceLineX,
                y: referenceLineY,
                type: 'scatter',
                mode: 'lines',
                name: 'Reference price',
                line: { color: "#000" }
            },
            {
                x: [car.km],
                y: [car.price],
                type: 'scatter',
                mode: 'markers',
                name: `Car selected`,
                marker: { color: car.category.includes('Grande autonomie') ? colors["red"] : car.category.includes('Performance') ? colors["yellow"] : colors["blue"] }
            }
        ];

        const layout = {
            title: {
                text: `Kilometre-based prices for ${carCategory} in ${car.year}`,
                font: {
                    size: 14,
                    family: 'Inter'
                }
            },
            xaxis: {
                title: {
                    text: 'Kilometer (km)',
                    font: {
                        family: 'Inter',
                        color: colors["bg-light"],
                        size: 12
                    }
                },
                // range: referenceLineX,
                zeroline: false,
                showline: true,
                showticklabels: true,
                showgrid: true,
                tickfont: {
                    family: 'Inter',
                    color: colors["bg-light"],
                    size: 9
                },
                tickcolor: colors["bg-light"],
                linecolor: colors["bg-light"],
            },
            yaxis: {
                title: {
                    text: 'Price (€)',
                    font: {
                        family: 'Inter',
                        color: colors["bg-light"],
                        size: 12
                    }
                },
                range: Math.max(...cars_prices) > 50000 ? [20000, 50000] : undefined,
                // range: [20000, Math.min(Math.max(...cars_prices)+5000, 50000)],
                zeroline: false,
                showline: true,
                showticklabels: true,
                showgrid: true,
                tickfont: {
                    family: 'Inter',
                    color: colors["bg-light"],
                    size: 9
                },
                tickcolor: colors["bg-light"],
                linecolor: colors["bg-light"],
            },
            legend: {
                font: {
                    family: 'Inter',
                    size: 10, 
                    color: "#aaaaaa"
                }
            },
            showlegend: false,
            font: {
                family: 'Inter'
            },
            margin: {
                l: 50,
                r: 0,
                b: 40,
                t: 30,
                pad: 4
            },
            responsive: true,
        };

        return <Plot data={data} layout={layout} />;
    };

}