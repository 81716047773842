import React, {useEffect} from "react";
import { Box } from "@mui/material";

export default function Ad({adSlot, adClient="ca-pub-2785110663009665", adFormat = "auto", dataAdLayoutKey = undefined, adFullWidthResponsive = true}) {

    useEffect(() => {
        //(window.adsbygoogle = window.adsbygoogle || []).push({});
        //const url = `${pathname}?${searchParams}`;
        //console.log("AdsenseComp -> router changed ", url);

        const scriptElement = document.querySelector('script[src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-2785110663009665"]');
        const handleScriptLoad = () => {
            try {
                if (window.adsbygoogle) {
                    console.log("pushing ads ");
                    window.adsbygoogle.push({});
                } else {
                    scriptElement.addEventListener("load", handleScriptLoad); 
                    console.log("waiting until adsense lib is loaded");
                }
            } catch (err) {
                console.log("error in adsense", err);
            }
        };

        handleScriptLoad();

        return () => {
            if (scriptElement) {
                scriptElement.removeEventListener("load", handleScriptLoad);
            }
        } 
    }, []);

    return (
        <Box>
            <ins className="adsbygoogle"
                style={{ display: 'block' }}
                data-ad-client={adClient}
                data-ad-slot={adSlot}
                data-ad-format={adFormat}
                data-ad-layout-key={dataAdLayoutKey}
                data-full-width-responsive={adFullWidthResponsive}></ins>
        </Box>
    );
}