import React from "react";
import { Link } from "react-router-dom";

export default function Footer() {

    return (
        <footer className="footer">
            <div className="footer-container">
                <div>
                    <span className="text-muted">© 2024 - Cars Finder</span>
                </div>
                <div>
                    <Link to="/mentions" className="footer-link">Mentions-légales</Link>
                </div>
            </div>
        </footer>
    );
}