import React, { useEffect } from 'react';
import { Modal, Box, Typography, Button, Stack, LinearProgress, Tooltip, CardMedia, Divider, Link } from '@mui/material';
import { Close as CloseIcon, Launch as LaunchIcon } from '@mui/icons-material';

import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import InfoIcon from '@mui/icons-material/Info';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import SpeedIcon from '@mui/icons-material/Speed';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import BlurOnOutlinedIcon from '@mui/icons-material/BlurOnOutlined';
import SellOutlinedIcon from '@mui/icons-material/SellOutlined';
import ShareIcon from '@mui/icons-material/Share';

import CarService from '../services/Car.service.js';
import PlotService from '../services/Plot.service.js';
// import useAnalyticsEventTracker from '../services/Analytics.service.js';
import sendEvent from '../services/Analytics.service.js';

import "../styles/modal.style.css"
import Ad from './ad.component.js';

const princing_tooltip = "This category provides a comprehensive analysis of the pricing for used car offers. The price is compared to the minimum and maximum prices of similar offers, taking into account the same year, category, and mileage. Additionally, a graph positions the offer relative to all similar offers currently or previously on the market with the same year and model. The market position of the offer helps to determine classifications based on various criteria and also estimates the number of days the car is likely to take to sell. All these factors are considered in calculating the final class of the offer."
const multivariableAffineFunctionsParams = JSON.parse("[{\"index\":\"Grande autonomie\",\"intercept\":-2831556.0395004507,\"coef_km\":-0.0651401696,\"coef_year\":1420.2625650568,\"r_squared\":0.7128750037},{\"index\":\"Performance\",\"intercept\":-2448501.7886863025,\"coef_km\":-0.071611894,\"coef_year\":1232.412400795,\"r_squared\":0.8175649088},{\"index\":\"Standard plus\",\"intercept\":-1599393.7673272435,\"coef_km\":-0.0592171432,\"coef_year\":808.2158680585,\"r_squared\":0.7038984237}]")

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
    bgcolor: 'background.paper',
    border: 'none',
    boxShadow: 24,
    p: 4,
};

export default function CarModal2({open, handleClose, car, allCars}) {

    const [lowerPrice, setLowerPrice] = React.useState(20000);
    const [higherPrice, setHigherPrice] = React.useState(60000);
    const [pourcentage, setPourcentage] = React.useState(50);
    const [pourcentageRef, setPourcentageRef] = React.useState(50);

    useEffect(() => {

        if (car === undefined || car === null) {
            return;
        }

        const sameCars = allCars.filter(item => item.year === car.year && item.category === car.category && Math.abs(item.km - car.km) <= 10000);

        if (sameCars.length === 0) {
            return;
        }

        const prices = sameCars.map(item => item.price);

        var min = Math.min(...prices);
        var max = Math.max(...prices);
        var pourcentage = (car.price - min) / (max - min) * 100;
        // Fit pourcentage to space in modal
        pourcentage = (pourcentage * (1 - 0.03 - 0.03)) + 3;

        var ref_price = car.class_v2.reference_price;
        var pourcentageRef = (ref_price - min) / (max - min) * 100;
        // Fit pourcentage to space in modal
        pourcentageRef = (pourcentageRef * (1 - 0.03 - 0.03)) + 3;

        setLowerPrice(min);
        setHigherPrice(max);
        setPourcentage(pourcentage > 96 ? 96 : pourcentage);
        setPourcentageRef(pourcentageRef > 96 ? 96 : pourcentageRef);
        

        car.image = CarService.formatImage(car.image);

    }, [car, allCars]);

    if (car === undefined || car === null) {
        return (
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                >
                <Box sx={style}>
                    <CloseIcon onClick={handleClose} style={{position: 'absolute', top: '10px', right: '10px', cursor: 'pointer'}}/>
                    <Typography id="modal-modal-title" variant="h3" component="h2">
                        No car selected
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    Please select a car to see more details.
                    </Typography>
                </Box>
            </Modal>
        )
    }

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            >
            <Box sx={style} className="modal-2-container">
                <Box className="modal-2-header">
                    <Box>
                        <Typography variant='h6'>{car.title} {car.model}</Typography>
                    </Box>
                    <Box>
                        <Box onClick={() => {navigator.clipboard.writeText(`${window.location.origin}/cars?=${car._id}`);}}>
                            <ShareIcon fontSize='10px'/>
                            <Typography variant='subtitle'>Share</Typography>
                        </Box>
                        <CloseIcon onClick={handleClose} />
                    </Box>
                </Box>
                <Box>
                    <Box className="modal-2-left">
                        <Box className="modal-2-left-price">
                            <Typography variant='h5'>{CarService.formatPrice(car.price)} €</Typography>
                            
                            <Tooltip title="Offer's class" placement="left">
                                <Box>
                                    <Typography variant='subtitle'>{car.class_v2 ? car.class_v2.class_v2_final : ""}</Typography>
                                </Box>
                            </Tooltip>
                        </Box>
                        <Divider />
                        <Box>
                            <Box>
                                <Typography variant='h6' className='modal-2-feature-title'>Features</Typography>
                            </Box>
                            <Box className="modal-2-feature">
                                <Box>
                                    <Box className="modal-2-feature-item">
                                        <Box>
                                            <BlurOnOutlinedIcon />
                                        </Box>
                                        <Box>
                                            <Typography variant='subtitle' className='modal-2-feature-item-title'>Category</Typography><br></br>
                                            <Typography variant='subtitle' className='modal-2-feature-item-texte'>{car.category_normalized}</Typography>
                                        </Box>
                                    </Box>
                                    <Box className="modal-2-feature-item">
                                        <Box>
                                            <SettingsOutlinedIcon />
                                        </Box>
                                        <Box>
                                            <Typography variant='subtitle' className='modal-2-feature-item-title'>Motor</Typography><br></br>
                                            <Typography variant='subtitle' className='modal-2-feature-item-texte'>{car.motor}</Typography>
                                        </Box>
                                    </Box>
                                    <Box className="modal-2-feature-item">
                                        <Box>
                                            <CalendarTodayOutlinedIcon />
                                        </Box>
                                        <Box>
                                            <Typography variant='subtitle' className='modal-2-feature-item-title'>Year</Typography><br></br>
                                            <Typography variant='subtitle' className='modal-2-feature-item-texte'>{car.year}</Typography>
                                        </Box>
                                    </Box>
                                </Box>
                                <Box>
                                    <Box className="modal-2-feature-item">
                                        <Box>
                                            <PersonOutlineOutlinedIcon />
                                        </Box>
                                        <Box>
                                            <Typography variant='subtitle' className='modal-2-feature-item-title'>Seller</Typography><br></br>
                                            <Typography variant='subtitle' className='modal-2-feature-item-texte'>{car.seller}</Typography>
                                        </Box>
                                    </Box>
                                    <Box className="modal-2-feature-item">
                                        <Box>
                                            <SpeedIcon />
                                        </Box>
                                        <Box>
                                            <Typography variant='subtitle' className='modal-2-feature-item-title'>Mileage</Typography><br></br>
                                            <Typography variant='subtitle' className='modal-2-feature-item-texte'>{car.km}</Typography>
                                        </Box>
                                    </Box>
                                    <Box className="modal-2-feature-item">
                                        <Box>
                                            <AccessTimeIcon />
                                        </Box>
                                        <Box>
                                            <Typography variant='subtitle' className='modal-2-feature-item-title'>Publication Date</Typography><br></br>
                                            <Typography variant='subtitle' className='modal-2-feature-item-texte'>{new Date(car.date * 1000).toLocaleDateString()}</Typography>
                                        </Box>
                                    </Box>
                                </Box>
                                <Box>
                                    <Box className="modal-2-feature-item">
                                        <Box>
                                            <SellOutlinedIcon />
                                        </Box>
                                        <Box>
                                            <Typography variant='subtitle' className='modal-2-feature-item-title'>Time to sell <i style={{fontSize: "10px"}}>(Estimation)</i></Typography><br></br>
                                            <Typography variant='subtitle' className='modal-2-feature-item-texte'>{car.class_v2 ? car.class_v2.days_to_sell_estimated : "Unknown"}</Typography>
                                        </Box>
                                    </Box>
                                    <Box className="modal-2-feature-item">
                                        <Box>
                                            <LocationOnOutlinedIcon />
                                        </Box>
                                        <Box>
                                            <Typography variant='subtitle' className='modal-2-feature-item-title'>Location</Typography><br></br>
                                            <Typography variant='subtitle' className='modal-2-feature-item-texte'>{car.location}</Typography>
                                        </Box>
                                    </Box>
                                    <Box className="modal-2-feature-item"></Box>
                                </Box>
                            </Box>
                        </Box>
                        <Divider />
                        <Box className="modal-2-pricing">
                            <Box>
                                <Typography variant='h6'>
                                    Pricing
                                </Typography>
                                <Tooltip title={princing_tooltip} placement="right">
                                    <InfoIcon fontSize='10px' style={{marginLeft: "5px", color: "#c3c3c3"}} />
                                </Tooltip>
                            </Box>
                            <Box className="modal-2-pricing-bar">
                                <Box>
                                    <Typography variant='caption' style={{left: `${pourcentage - 2.5}%`}}>{CarService.formatPrice(car.price)}€</Typography>
                                </Box>
                                <Box>
                                    <Box className="modal-2-pricing-bar-color"></Box>
                                    <Box className="modal-2-pricing-bar-lower"></Box>
                                    <Box className="modal-2-pricing-bar-higher"></Box>
                                    <Box className="modal-2-pricing-bar-reference" style={{left: `${pourcentageRef}%`}}></Box>
                                    <Box className="modal-2-pricing-bar-round" style={{left: `${pourcentage}%`}}></Box>
                                </Box>
                                <Box>
                                    <Box>
                                        <Typography variant='caption'>{lowerPrice} €</Typography>
                                    </Box>
                                    <Box>
                                        <Typography variant='caption'>{higherPrice} €</Typography>
                                    </Box>
                                </Box>
                            </Box>
                            <Box>
                                {PlotService.CarPricePlotWithYear2(car, allCars, multivariableAffineFunctionsParams)}
                            </Box>
                            <Box>
                                <Box>
                                    <Box className="modal-2-pricing-bar-reference-legend"></Box>
                                    <Typography variant='caption'>: Reference price ({Math.round(car.class_v2.reference_price ? car.class_v2.reference_price : 0, 2)} €)</Typography>
                                </Box>
                                <Box>
                                    <Box className="modal-2-pricing-graph-other-legend"></Box>
                                    <Typography variant='caption'>: Other offers</Typography>
                                </Box>
                                <Box>
                                    <Box className="modal-2-pricing-graph-selected-legend" style={{backgroundColor: car.category.includes('Grande autonomie') ? "#aa002b" : car.category.includes('Performance') ? "#E0CA3C" : "#06387e"}}></Box>
                                    <Typography variant='caption'>: Car selected</Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <Box className="modal-2-right">
                        <Box className="modal-2-image" style={{backgroundImage: `url(${car.image})`}}></Box>
                        <Divider />
                        <Box>
                            <Box>
                                <Typography variant='h6'>Offers</Typography>
                            </Box>
                            <Box className="modal-2-urls">
                                {
                                    car.final_urls ? car.final_urls.split(',').map((url, index) => {
                                        try {
                                            var website = url.split('www.')[1].split('/')[0];
                                            return (
                                                <Link key={index} href={url} target="_blank" underline="none">
                                                    <Box className="modal-2-url-item">
                                                        <Typography variant='caption'>{website}</Typography>
                                                        <ArrowForwardIcon />
                                                    </Box>
                                                </Link>
                                            )
                                        } catch (error) {
                                            return null
                                        }
                                    }) : null
                                }
                            </Box>
                        </Box>
                        <Box>
                            {/* <Ad adFormat='fluid' adSlot={"1836674624"} dataAdLayoutKey={"in-article"} /> */}
                            <Ad adFormat='auto' adSlot={"1992038553"} />
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Modal>
    )
}