import { Chip } from '@mui/material';

const ads_limit = 1000000;
const gap = 2000;
const affineFunctionsParams = JSON.parse("{\"slope\":{\"Grande autonomie\":-0.0703394939,\"Performance\":-0.0694263739,\"Standard plus\":-0.0547044384},\"intercept\":{\"Grande autonomie\":39456.6619862972,\"Performance\":41070.0024580641,\"Standard plus\":33536.6515275233},\"r_value\":{\"Grande autonomie\":-0.9720437756,\"Performance\":-0.9261265059,\"Standard plus\":-0.9530390696}}")
// const multivariableAffineFunctionsParams = JSON.parse("[{\"index\":\"Grande autonomie\",\"intercept\":-1857917.6630637632,\"coef_km\":-0.0623368598,\"coef_year\":938.6875729652,\"r_squared\":0.7918835051},{\"index\":\"Performance\",\"intercept\":-1351735.8026589553,\"coef_km\":-0.0633091694,\"coef_year\":689.1112164521,\"r_squared\":0.650360483},{\"index\":\"Standard plus\",\"intercept\":-1523614.5556714656,\"coef_km\":-0.0489950524,\"coef_year\":770.2539122212,\"r_squared\":0.6460135218}]")
const multivariableAffineFunctionsParams = JSON.parse("[{\"index\":\"Grande autonomie\",\"intercept\":-2655605.5718366439,\"coef_km\":-0.0592460224,\"coef_year\":1333.1343961294,\"r_squared\":0.6593617703},{\"index\":\"Performance\",\"intercept\":-2757745.871667082,\"coef_km\":-0.0749748021,\"coef_year\":1385.7417154771,\"r_squared\":0.7776118498},{\"index\":\"Standard plus\",\"intercept\":-1749868.1902290503,\"coef_km\":-0.0527549152,\"coef_year\":882.3792940598,\"r_squared\":0.6589527538}]")

export default class CarService {
    
    static fetchCars = async (filter) => {
        var data = await fetch(`https://workspace.gavazziadrien.fr/car/cars?limit=${ads_limit}${filter ? "&filter=" + JSON.stringify(filter) : ""}`, { method: 'GET' })
            .then(response => {return response.json()})
            .catch(error => console.error('Erreur:', error));

        if (data && data.length > 0) {
            data.forEach(car => {

                if (car.motor === null || car.motor === 'nan' || car.motor === undefined) {
                    car.motor = car.category.includes("AWD") ? "AWD" : car.category.includes("RWD") ? "RWD" : "";
                }

                if (car.category_normalized === null || car.category_normalized === 'nan' || car.category_normalized === undefined) {
                    car.category = this.normalizeCategoryName(car.category);
                    
                    let categoryParams = "Standard plus";

                    if (car.category) {
                        categoryParams = car.category.includes("Grande autonomie") ? "Grande autonomie" : categoryParams;
                        categoryParams = car.category.includes("Performance") ? "Performance" : categoryParams;
                    }

                    car.category_normalized = categoryParams;
                }

                try {
                    if (car.class_v2 !== null && car.class_v2 !== 'nan' && car.class_v2 !== undefined && car.class_v2 !== "") {
                        car.class_v2 = JSON.parse(car.class_v2);
                    }
                } catch (error) {
                    console.error("Error parsing class_v2", error);
                }
                
            });

            // this.populateFilters(data);

            // let options = this.checkPreset();

            // const sortSelect = document.getElementById('sortSelect').value;

            // var cars = this.sortCars(data, sortSelect);

            // var filteredCars = this.filterByPeriod(cars, periodSelect);

            // Remove duplicates offers
            data = this.mergeListings(data);

            // Determine the category and motor type for each car if not already set
            data = this.autoCompleteCategoryAndMotor(data);

            return data
        } else {
            return [];
        }
    }

    static mergeListings(listings) {
        const mergedListings = new Map();
        const kmTolerance = 2000; // Tolérance pour le kilométrage
        const priceTolerance = 1000; // Tolérance pour le prix
        var nb_duplicates = 0;

        listings.forEach(listing => {
            const key = `${listing.model}-${listing.year}-${listing.category}-${listing.zip_code}-${listing.website}`;
            if (mergedListings.has(key)) {
                let matched = false;
                let listingsArray = mergedListings.get(key);
                listingsArray.forEach(existingListing => {
                    // Vérifier si le kilométrage et le prix sont dans la plage de tolérance et si aucune fusion n'a été faite
                    if (!matched && Math.abs(existingListing.km - listing.km) <= kmTolerance && Math.abs(existingListing.price - listing.price) <= priceTolerance) {
                        nb_duplicates += 1;
                        matched = true;
                        //console.log("Duplicate found:", existingListing.link, listing.link);
                        // Mise à jour des champs basés sur la logique de fusion
                        existingListing.created_at = Math.min(existingListing.created_at, listing.created_at);
                        existingListing.km = Math.max(existingListing.km, listing.km);
                        existingListing.price = Math.min(existingListing.price, listing.price);
                        existingListing.last_seen = Math.max(existingListing.last_seen, listing.last_seen);
                        existingListing.date = Math.max(existingListing.date, listing.date);
                    }
                });
                if (!matched) {
                    listingsArray.push(listing);
                }
            } else {
                mergedListings.set(key, [listing]);
            }
        });

        // console.log("Number of duplicates:", nb_duplicates);
        // Convertir les tableaux de chaque clé en une seule annonce, en prenant par exemple la première annonce
        let finalListings = [];
        for (let listingsArray of mergedListings.values()) {
            finalListings.push(...listingsArray);
            //for (let listing of listingsArray) {
            //    finalListings.push(listing);
            //}
            //finalListings.push(listingsArray[0]); // prend la première annonce du tableau ou applique une autre logique de réduction
        }
        return finalListings;
    }

    static normalizeCategoryName(category) {
        if (category === null || category === 'nan' || category === undefined) {
            return null; // Retourner null pour les valeurs non valides ou vides
        }
        // Remplacements pour ajouter des espaces manquants et unifier les noms de catégorie
        const replacements = {
            'Grande autonomieAWD': 'Grande autonomie AWD',
            'Standard plusRWD': 'Standard plus RWD',
            'PerformanceAWD': 'Performance AWD',
            'PerformancePUPAWD': 'Performance PUP AWD',
            'Grande autonomieRWD': 'Grande autonomie RWD',
        };
        // Appliquer les remplacements
        for (const [original, corrected] of Object.entries(replacements)) {
            category = category.replace(original, corrected);
        }
        return category;
    }

    static autoCompleteCategoryAndMotor(cars) {

        function getMotorByCategory(category, year) {
            switch (category) {
                case 'Grande autonomie':
                    if (year < 2019) {
                        return '';
                    } else if (year === 2019) {
                        return 'AWD or RWD';
                    }
                    return 'AWD';
                case 'Performance':
                    return 'AWD';
                case 'Standard plus':
                    return 'RWD';
                default:
                    return '';
            }
        }

        function getCategoryByMotor(motor, year) {
            switch (motor) {
                case 'AWD':
                    if (year < 2019) {
                        return '';
                    }
                    return 'Grande autonomie or Performance';
                case 'RWD':
                    if (year < 2019 || year > 2019) {
                        return 'Standard plus';
                    }
                    return 'Standard plus or Grande autonomie';
                default:
                    return '';
            }
        }
        

        cars.forEach(car => {
            if ((car.category_normalized === '' || car.category_normalized === undefined) && (car.motor === '' || car.motor === undefined) && car.year < 2019) {
                car.category_normalized = 'Standard plus';
                car.motor = 'RWD';
                
            } else {
                if (car.motor === '' || car.motor === undefined) {
                    car.motor = getMotorByCategory(car.category_normalized, car.year);
                }
                if (car.category_normalized === '' || car.category_normalized === undefined) {
                    car.category_normalized = getCategoryByMotor(car.motor, car.year);
                }
            }
        })

        return cars;
    }

    static formatImage(image) {

        if (image && image.includes("https://www.leparking.frhttps")) {
            image = image.replace("https://www.leparking.frhttps", "https");            
        }

        if (image && image.includes("https//")) {
            image = image.replace("https//", "https://");
        }

        return image;
    }

    static sortCars(cars, sortKey) {
        switch(sortKey) {
            case 'price-asc':
                return cars.sort((a, b) => a.price - b.price);
            case 'price-desc':
                return cars.sort((a, b) => b.price - a.price);
            case 'date-asc':
                return cars.sort((a, b) => new Date(a.created_at * 1000) - new Date(b.created_at * 1000));
            case 'date-desc':
                return cars.sort((a, b) => new Date(b.created_at * 1000) - new Date(a.created_at * 1000));
            default:
                return cars; // Retourne la liste non triée si aucun critère de tri n'est sélectionné
        }
    }

    static filterByPeriod(cars, period) {
        
        const now = new Date();
        return cars.filter(car => {
            const dateAdded = new Date(car.created_at * 1000);
            
            switch(period) {
                case '24h':
                    return (now - dateAdded) < 24 * 60 * 60 * 1000;
                case '7d':
                    return (now - dateAdded) < 7 * 24 * 60 * 60 * 1000;
                case '1m':
                    return (now - dateAdded) < 30 * 24 * 60 * 60 * 1000;
                case '3m':
                    return (now - dateAdded) < 90 * 24 * 60 * 60 * 1000;
                case '1y':
                    return (now - dateAdded) < 365 * 24 * 60 * 60 * 1000;
                case 'all':
                default:
                    return true; // Ne filtre pas si "Toutes les périodes" est sélectionné
            }
        });
    }

    // Fonction pour calculer la différence de prix par rapport à la référence
    static calculatePriceDifference(car, categoryParams, newMethod=false) {
        if (newMethod) {
            return ((car.price - this.calculateReferencePrice(categoryParams, car)).toFixed(2));
        } else {
            return ((car.price - (car.km * categoryParams.slope + categoryParams.intercept)).toFixed(2));
        }
    }

    static calculateReferencePrice(regressionParamsJson, carOffer) {
        // Parse le JSON pour obtenir les paramètres de régression
        const regressionParams = regressionParamsJson;

        let categoryParams = "Standard plus";

        if (carOffer.category) {
            categoryParams = carOffer.category.includes("Grande autonomie") ? "Grande autonomie" : categoryParams;
            categoryParams = carOffer.category.includes("Performance") ? "Performance" : categoryParams;
        }

        // Trouver les paramètres pour le modèle et l'année correspondants de la voiture
        const matchingParams = regressionParams.find(p => p.index === categoryParams);

        if (matchingParams) {
            // Calculer le prix de référence en utilisant la formule de régression linéaire
            const referencePrice = matchingParams.intercept
                                + matchingParams.coef_km * carOffer.km
                                + matchingParams.coef_year * carOffer.year;
            return referencePrice;
        } else {
            // Aucun paramètre trouvé pour le modèle et l'année, retourner une erreur ou null
            return null;
        }
    }

    static calculateReliability(car, cars) {
        if (!car || !cars || cars.length === 0) {
            return 0; // Retourne 0 si les données sont manquantes ou vides
        }
        const carCategory = car.category.includes("Grande autonomie") ? "Grande autonomie" : car.category.includes("Performance") ? "Performance" : "Standard plus";
        const carsInCategory = cars.filter(d => d.category === carCategory && d.year === car.year);
        const carsNearKm = cars.filter(d => d.category === carCategory && d.year === car.year && Math.abs(d.km - car.km) <= 10000); // +/- 10000 km
        
        const prices = carsNearKm.map(d => d.price); // Extraction des prix des voitures proches en kilométrage
        const averagePrice = prices.reduce((acc, cur) => acc + cur, 0) / prices.length; // Calcul de la moyenne des prix
        const variance = prices.length > 0 ? prices.reduce((acc, cur) => acc + (cur - averagePrice) ** 2, 0) / prices.length : 0; // Calcul de la variance
        const stdDev = Math.sqrt(variance); // Calcul de l'écart type
        
        // console.log("Total cars in category:", carsInCategory.length, "Total cars near km:", carsNearKm.length, "Average price:", averagePrice, "Variance:", variance, "Std Dev:", stdDev)

        // Définir des seuils pour une haute fiabilité
        const highThresholdCategory = 150; // Nombre minimal de voitures dans la catégorie pour une haute fiabilité
        const highThresholdKm = 25; // Nombre minimal de voitures proches en km pour une haute fiabilité
        const stdDevThreshold = 3500; // Seuil de l'écart type

        // Calculer les scores partiels, normalisés entre 0 et 1
        const categoryScore = Math.min(carsInCategory.length / highThresholdCategory, 1);
        const kmScore = Math.min(carsNearKm.length / highThresholdKm, 1);
        const stdDevScore = stdDev < stdDevThreshold ? (1 - (stdDev / stdDevThreshold)) / 2 : 0;

        // Somme des scores pour un score final, normalisé à nouveau entre 0 et 1
        const totalScore = categoryScore + kmScore + stdDevScore;
        const maxScore = 2.5; // La somme maximale des scores partiels avant normalisation
        const reliabilityScore = totalScore / maxScore; // Normalise le score final entre 0 et 1

        // console.log("Total reliability score without std dev:", (categoryScore + kmScore) / 2);
        // console.log("Category score:", categoryScore, "Km score:", kmScore, "Std Dev score:", stdDevScore, "Total reliability score:", reliabilityScore);

        return Math.floor(reliabilityScore * 100); // Retourne un score sur 1
    }

    // Fonction pour calculer la classe de l'offre
    static calculateClass(difference) {
        // let rawClass = Math.ceil(Math.abs(difference) / gap);
        let rawClass = difference < 0 ? Math.ceil(difference / gap) : Math.floor(difference / gap);
        // console.log("Math.", difference < 0 ? "ceil": "floor" ,"(" + difference + " / " + gap + "): ", Math.floor(difference / gap));
        return Math.min(Math.max(rawClass, -5), 5) * -1;
    }

    static generateCategoryBadge(category) {
        let badgeColor = '';
        let badgeText = '';

        if (category === null || category === 'nan' || category === undefined){
            return ''; // Retourne une chaîne vide si la catégorie est nulle
        }

        if (category.includes('Grande autonomie')) {
            badgeColor = 'bg-red'; // Vert pour "Grande autonomie"
            badgeText = 'Grande autonomie';
        } else if (category.includes('Standard plus')) {
            badgeColor = 'bg-blue'; // Jaune pour "Standard Plus"
            badgeText = 'Standard Plus';
        } else if (category.includes('Performance')) {
            badgeColor = 'bg-yellow'; // Rouge pour "Performance"
            badgeText = 'Performance';
        }

        if (badgeText) {
            // return <Chip label={badgeText} className={badgeColor + " chip"} />
            return <div className={badgeColor + " chip"}>{badgeText}</div>
        } else {
            return ''; // Retourne une chaîne vide si aucun badge n'est applicable
        }
    }

    static generateEngineTypeBadge(category) {
        let badgeText = 'RWD';
        let badgeColor = 'bg-grey'; // Couleur par défaut pour les badges de type de moteur

        if (category === null || category === 'nan' || category === undefined){
            return ''; // Retourne une chaîne vide si la catégorie est nulle
        }

        if (category.includes('AWD')) {
            badgeText = 'AWD';
            badgeColor = "bg-cyan"; // Vert pour "AWD"
        } else if (category.includes('RWD')) {
            badgeText = 'RWD';
        }

        // return badgeText ? <Chip label={badgeText} className={badgeColor + " chip"} /> : '';
        return badgeText ? <div className={badgeColor + " chip"}>{badgeText}</div> : '';
    }

    static getColorIndex(carClass) {
        // if (carClass >= 4) {
        //     return 0; // Vert foncé pour les offres les plus avantageuses
        // } else if (carClass >= 2) {
        //     return 1; // Vert plus clair
        // } else if (carClass >= -1) {
        //     return 2; // Jaune
        // } else if (carClass >= -3) {
        //     return 3; // Orange
        // } else {
        //     return 4; // Rouge pour les offres les moins avantageuses
        // }
        if (carClass < 0) {
            return 5
        } else if (carClass === 0) {
            return 4
        } else if (carClass === 1) {
            return 3
        } else if (carClass === 2) {
            return 2
        } else if (carClass === 3) {
            return 1
        } else {
            return 0
        }
    }

    static calculateOfferInfos(cars) {
        
        const now = new Date();
        const twoHoursAgo = new Date(now.getTime() - (7 * 60 * 60 * 1000));
        const twentyFourHoursAgo = new Date(now.getTime() - (24 * 60 * 60 * 1000));
        const sevenDaysAgo = new Date(now.getTime() - (7 * 24 * 60 * 60 * 1000));
        const oneMonthAgo = new Date(now.getTime() - (30 * 24 * 60 * 60 * 1000));

        const twoHoursAds = cars.filter(car => new Date(car.created_at * 1000) > twoHoursAgo).length;
        const twentyFourHoursAds = cars.filter(car => new Date(car.created_at * 1000) > twentyFourHoursAgo).length;
        const sevenDaysAds = cars.filter(car => new Date(car.created_at * 1000) > sevenDaysAgo).length;
        const oneMonthAds = cars.filter(car => new Date(car.created_at * 1000) > oneMonthAgo).length;

        return {"8h": twoHoursAds, "24h": twentyFourHoursAds, "7d": sevenDaysAds, "total": cars.length, "1m": oneMonthAds};
    }

    static formatPrice(price) {
        // Convertir le prix en nombre si c'est une chaîne de caractères
        if (typeof price === 'string') {
            price = parseFloat(price.replace(/\s+/g, ''));
        }

        // Vérifier si la conversion en nombre a réussi
        if (isNaN(price)) {
            throw new Error('Invalid price input');
        }

        // Formater le prix avec des espaces pour les milliers et les millions
        return price.toLocaleString('fr-FR', { minimumFractionDigits: 0, maximumFractionDigits: 2 });
    }
    
}