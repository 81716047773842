import React from 'react';
import { Box, Typography, LinearProgress, Tooltip, Stack, Card, CardContent, CardMedia, CardActionArea, Divider } from '@mui/material';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import SpeedIcon from '@mui/icons-material/Speed';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import EuroIcon from '@mui/icons-material/Euro';

import CarService from '../services/Car.service.js';

const km_max = 200000;
const price_max	= 50000;
const colors_opacity = "CC";
const colors = [`#038141${colors_opacity}`, `#85bb2f${colors_opacity}`, `#fecb02${colors_opacity}`, `#ee8100${colors_opacity}`, `#e63e11${colors_opacity}`, `#b02600${colors_opacity}`]; // Vert, Vert clair, Jaune, Orange, Rouge*
const affineFunctionsParams = JSON.parse("{\"slope\":{\"Grande autonomie\":-0.0703394939,\"Performance\":-0.0694263739,\"Standard plus\":-0.0547044384},\"intercept\":{\"Grande autonomie\":39456.6619862972,\"Performance\":41070.0024580641,\"Standard plus\":33536.6515275233},\"r_value\":{\"Grande autonomie\":-0.9720437756,\"Performance\":-0.9261265059,\"Standard plus\":-0.9530390696}}");

export default function CarCard({car, handleOpen}) {

    const today = new Date().toISOString().split('T')[0];

    //const progressPercent_km = Math.min(car.km / km_max * 100, 100);
    //const progressPercent_price = Math.min(car.price / price_max * 100, 100);
    //const categoryBadgeHTML = CarService.generateCategoryBadge(car.category);
    //const engineTypeBadgeHTML = CarService.generateEngineTypeBadge(car.category);
    const isAvailable = new Date(car.last_seen * 1000).toISOString().split('T')[0] === today;

    car.image = CarService.formatImage(car.image);

    let sold_image = '';
    if (!isAvailable) {
        sold_image = `<img class="img_sold" src="https://static.vecteezy.com/system/resources/previews/019/787/028/non_2x/sold-icon-on-transparent-background-free-png.png"/>`;
    }

    var color = colors[CarService.getColorIndex(car.class)];

    const analysis_message = () => {
        let message = '';
        let color_message = ''
        let color_background = ''
        switch (car.class) {
            case 5:
                message = 'Excellent Deal'
                color_message = 'green'
                color_background = '#f0f0f0'
                break;
            case 4:
                message = 'Good Deal'
                color_message = 'green'
                color_background = '#f0f0f0'
                break;
            case 3:
                message = 'Underpriced'
                color_message = 'orange'
                color_background = '#FFF4E5'
                break;
        
            default:
                return null;
                break;
        }
        return (
            <Box className="car-card-class" style={{backgroundColor: color_background, border: `solid 1px ${color_message}`}}>
                <Box style={{border: `solid 1.5px ${color_message}`,}}>
                    <EuroIcon style={{color: color_message}} fontSize='10px' />
                </Box>
                <Typography variant="body2" style={{color: color_message, margin: "auto"}}>{message}</Typography>
            </Box>
        );
    }
    
    return (
        <Card key={car._id} onClick={() => handleOpen(car)} className='car-card'>
            <CardActionArea>
                {/*<Typography variant="h5" style={{position: "absolute", zIndex: 1}}>{car.class}</Typography>*/}
                <Box>
                    <Box className='car-card-image'>
                        <CardMedia
                            component="img"
                            height="200"
                            image={car.image}
                            alt=""
                            />
                        {analysis_message()}
                    </Box>
                    <Box style={{position: 'relative'}}>
                        <Box className="car-card-header">
                            <Box>
                                <Typography variant="subtitle1" className="car-card-title">{car.model}</Typography>
                            </Box>
                            <Box>
                                <Typography variant="subtitle1" className="car-card-price">{car.price} €</Typography>
                            </Box>
                        </Box>
                        <Box className="car-card-cat">
                            <Typography variant="subtitle2" className="car-card-cat-text">{car.category_normalized} {car.motor}</Typography>
                        </Box>
                    </Box>
                    <Divider />
                    <Box>
                        <Box className="car-card-desc">
                            <Box>
                                <CalendarTodayOutlinedIcon />
                                <Typography variant="body2">{car.year}</Typography>
                            </Box>
                            <Box>
                                <PermIdentityIcon />
                                <Typography variant="body2">{car.seller}</Typography>
                            </Box>
                            <Box>
                                <SpeedIcon />
                                <Typography variant="body2">{car.km} km</Typography>
                            </Box>
                            <Box>
                                <LocationOnOutlinedIcon />
                                <Typography variant="body2">{car.location}</Typography>
                            </Box>
                            <Box>
                                <AccessTimeIcon />
                                <Typography variant="body2">{new Date(car.last_seen * 1000).toISOString().split("T")[0]}</Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </CardActionArea>
        </Card>   
    );
}