import { Button } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Typography } from '@mui/material';

import "../styles/home.style.css"
import Footer from '../components/footer.component';
import { Helmet } from 'react-helmet';


export default function HomeScreen() {

    return (
        <div style={{textAlign: 'center'}}>
			<Helmet>
    			<title>Home - Model 3 Finder</title>
			</Helmet>
			<Box className="home-container">
				<Box className="home-navbar">
					<Box>
						<Link to="/">
							<Box>
								Home
							</Box>
						</Link>
						<Link to="/cars">
							<Box>
								Cars
							</Box>
						</Link>
					</Box>
				</Box>
				<Box className="home-img">
					<img src={require("../assets/images/home-img-0.jpg")} alt="Tesla Model 3" />
					<Box>
						<Typography variant="h4">Welcome to the</Typography>
						<Typography variant="h3"><strong className='tesla-font'>TESLA</strong> model 3 finder</Typography>
						<Link to="/cars">Explore Offers</Link>
					</Box>
				</Box>
				<Box className="home-welcome">
					<Box>
						<Box>
							<Typography variant="body1">
								Tesla Finder is a personal project developed to simplify the search for used Tesla vehicles in France. By harnessing advanced data collection and analysis techniques, this site aggregates the best online listings and evaluates them using a proprietary formula to determine their value.
							</Typography>
						</Box>
						<Box>
							<img src={require("../assets/images/home-img-2.webp")} alt='tesla-finder' />
						</Box>
					</Box>
				</Box>
				<Box className="home-explore">
					<Box>
						<Typography variant="h4">Explore Car Listings</Typography>
						<Typography variant="body1">
							Ready to find your perfect Tesla? Explore our curated listings now. Just click the button below to get started!
						</Typography>
						<Link to="/cars">Explore Listings</Link>
					</Box>
				</Box>
				<Box className="home-techno">
					<Box>
						<Typography variant="h4">Our Technology</Typography>
						<Typography variant="body1">
							This platform utilizes an automated process to extract relevant information from websites, employing cutting-edge tools like Python and BeautifulSoup. The collected data, including price, mileage, and model year, is regularly updated and securely stored. We also use machine learning methods for accurate image classification, allowing you to quickly verify if the listing matches the model you're seeking.
						</Typography>
					</Box>
					<Box>
						<img src={require("../assets/images/home-img-3.webp")} alt='our-techno' />
					</Box>
				</Box>
				<Box className="home-join">
					<Box>
						<Typography variant="h4">Why Use Tesla Finder?</Typography>
						<Box>
							<Box>
								<strong>Objective Ratings</strong><br></br> Each listing is scored based on stringent criteria, helping you quickly understand the value of the deal.
							</Box>
							<Box>
								<strong>Ease of Search</strong><br></br> Designed for intuitive navigation, our interface allows you to filter and sort listings according to your personal preferences.
							</Box>
							<Box>
								<strong>Data Visualization</strong><br></br> Dive into visual analyses of market trends, aiding in identifying the best times to buy.
							</Box>
						</Box>
						<Typography variant="body1">
							Tesla Finder is not just a search platform; it's an essential tool for any potential buyer or Tesla enthusiast, providing all the necessary information to make an informed decision.
						</Typography>
					</Box>
				</Box>
				<Box className="home-bannere">
					<Box className="lineaire-simple"></Box>
				</Box>
				<Box className="home-end">
					<Box>
						<Typography variant="body1">
							<strong>Join the Tesla Finder community today and discover your ideal Tesla with confidence and ease as we continue to develop and enhance this project based on user feedback.</strong>
						</Typography>
					</Box>
				</Box>
			</Box>
            {/* <h1>Home page</h1>
            <Link to="/cars">
                <Button variant="contained" color="primary">Go to cars</Button>
            </Link> */}
			<Footer />
        </div>
    );
}

