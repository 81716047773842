import React from "react";
import ReactGA from "react-ga4";

// const useAnalyticsEventTracker = (category="Blog category") => {
//   const eventTracker = (action = "action", label = "label") => {
//     ReactGA.event({category, action, label});
//   }
//   return eventTracker;
// }
// export default useAnalyticsEventTracker;

function sendEvent(category, action, label=undefined, value=undefined) {
  ReactGA.event({
    category: category,
    action: action,
    label: label, // optional
    value: value, // optional, must be a number
  });
}

export default sendEvent;