import React, { useEffect } from 'react';
import { 
    FormControl, 
    InputLabel, 
    Select, 
    MenuItem, 
    FormGroup, 
    FormControlLabel, 
    Checkbox, 
    Slider, 
    Typography, 
    Box, 
    Switch, 
    Button, 
    Divider,
    ToggleButton,
    ToggleButtonGroup,
    OutlinedInput,
    FormHelperText,
    InputAdornment
    } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { checkboxClasses } from "@mui/material/Checkbox";

import CarService from '../services/Car.service.js';
import Ad from './ad.component.js';

export default function FilterComponent({cars, setCars, allCars, largePeriod, setLargePeriod, sort, setSort, btnClose, toggleDrawer}) {
    
    // Gestion des états pour les différents filtres
    const [period, setPeriod] = React.useState('24h');
    const [sold, setSold] = React.useState(true);
    const [priceRange, setPriceRange] = React.useState([0, 40000]);
    const [years, setYears] = React.useState([]);
    const [year, setYear] = React.useState([]);
    const [category, setCategory] = React.useState('all');
    const [categories, setCategories] = React.useState([]);
    const [location, setLocation] = React.useState('all');
    const [locations, setLocations] = React.useState([]);
    const [seller, setSeller] = React.useState(["Particulier", "Professionnel"]);
    const [carClass, setCarClass] = React.useState('all-class');

    useEffect(() => {
    
        if (allCars === undefined || allCars.length === 0) {
            return;
        }

        const prices = allCars.map(car => parseInt(car.price, 10));
        const minPrice = Math.min(...prices, 40000);
        const maxPrice = Math.max(...prices, 0);

        setPriceRange([minPrice, maxPrice]);

        //var tmpYears = [...new Set(allCars.map(car => Math.ceil(car.year) > 2018 ? car.year : 2018))].sort((a, b) => b - a);
        var tmpYears = [...new Set(allCars.map(car => car.year))].sort((a, b) => b - a);
        setYears(tmpYears);
        setYear(tmpYears);
        // setCategories([...new Set(allCars.map(car => car.category))].sort((a, b) => b - a));
        setCategories(["Grande Autonomie", "Performance", "Standard Plus"]);
        setLocations([...new Set(allCars.map(car => car.location))]);
        
    }, [allCars])

    useEffect(() => {
        if (allCars === undefined || allCars.length === 0) {
            return;
        }

        var tmp = CarService.filterByPeriod(allCars, period);

        // console.log(sort, sold, priceRange, year, category, location, carClass, seller)

        tmp = tmp.filter(car => {
            const carYear = car.year.toString();
            const carPrice = parseInt(car.price, 10);
            const isAvailable = sold ? new Date(car.last_seen * 1000).toISOString().split('T')[0] === new Date().toISOString().split('T')[0] : true;
            const matchesCategory = category === "" || category === "all" || car.category === category || ( car.category && car.category.includes(category));
            const matchesLocation = location === "" || location === "all" || car.location === location;
            const matchesYear = year.toString() === "" || year.includes(Math.ceil(carYear)); 
            const matchesSeller = seller.length === 0 || seller.includes(car.seller);
            const matchesCarClass = carClass === "all-class" || (carClass === "class-sup-0" && car.class > 0) || (carClass === "class-sup-3" && car.class > 3);

            if (!isAvailable || !matchesCategory || !matchesLocation || !matchesYear || !matchesSeller || !matchesCarClass) {
                if ((!matchesCategory || !matchesLocation || !matchesYear || !matchesSeller || !matchesCarClass)) {
                    console.log("car", car, "isAvailable", isAvailable, "matchesCategory", matchesCategory, "matchesLocation", matchesLocation, "matchesYear", matchesYear, "matchesSeller", matchesSeller, "matchesCarClass", matchesCarClass)
                }
            }

            return matchesYear && carPrice >= priceRange[0] && carPrice <= priceRange[1]
                && isAvailable && matchesCategory && matchesLocation && matchesCarClass && matchesSeller;
        });
        tmp = CarService.sortCars(tmp, sort);
        setCars(tmp);

    }, [period, sort, sold, priceRange, year, category, location, carClass, years, seller])

    function reset_filters() {
        setPeriod('24h');
        setSort('date-desc');
        setSold(true);
        setYear([...new Set(allCars.map(car => car.year))].sort((a, b) => b - a));
        setCategory('all');
        setLocation('all');
        setCarClass('all-class');
        
        const prices = allCars.map(car => parseInt(car.price, 10));
        const minPrice = Math.min(...prices, 40000);
        const maxPrice = Math.max(...prices, 0);
        setPriceRange([minPrice, maxPrice]);
    }

    // Fonctions de gestion pour chaque filtre
    const handlePeriodChange = (event) => {
        if ((event.target.value === 'all' || event.target.value === "1y" || event.target.value === "3m") && !largePeriod) {
            setLargePeriod(true);
        }
        setPeriod(event.target.value);
    };

    const handlePriceChange = (event, index) => {
        const newPriceRange = [...priceRange];
        newPriceRange[index] = Math.ceil(event.target.value);
        setPriceRange(newPriceRange);
    };

    const handleYearChange = (event) => {
        if (event.target.checked) { 
            setYear([...year, parseInt(event.target.name)]);
        } else {
            setYear(year.filter(y => y !== parseInt(event.target.name)));
        }
    };

    const handleSellerChange = (event) => {
        if (event.target.checked) {
            setSeller([...seller, event.target.name]);
        } else {
            setSeller(seller.filter(s => s !== event.target.name));
        }
    };

    const handleCategoryChange = (event) => {
        setCategory(event.target.value);
    };

    const handleLocationChange = (event) => {
        setLocation(event.target.value);
    };

    const handleClassChange = (event) => {
        setCarClass(event.target.value);
    };
    

    return (
        <Box sx={{ minWidth: 100 }} className="filter-container">
            {
                btnClose ?
                <Box className="filter-close" onClick={toggleDrawer(false)}>
                    <CloseIcon />
                </Box>
                : null
            }
            <Box className="filter-header">
                <Box>
                    <Typography variant="h6" gutterBottom>Filter</Typography>
                </Box>
                <Box className="filter-reset">
                    <Box onClick={reset_filters}>
                        Reset
                    </Box>
                </Box>
            </Box>
            <Divider />

            <Box className="filter-period">
                <Typography variant='h6'>Period</Typography>
                <ToggleButtonGroup
                    color="primary"
                    value={period}
                    exclusive
                    onChange={handlePeriodChange}
                    aria-label="Platform"
                    >
                    <ToggleButton value="all">ALL</ToggleButton>
                    <ToggleButton value="24h">24H</ToggleButton>
                    <ToggleButton value="7d">7D</ToggleButton>
                    <ToggleButton value="1m">1M</ToggleButton>
                    <ToggleButton value="3m">3M</ToggleButton>
                    <ToggleButton value="1y">1Y</ToggleButton>
                </ToggleButtonGroup>
            </Box>

            <Box className="filter-price">
                <Typography variant='h6'>Price</Typography>
                <Box>
                    <FormControl sx={{ m: 1, width: '100%' }} variant="outlined">
                        <InputLabel htmlFor="outlined-adornment-price-min">Min.</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-price-min"
                            type={'text'}
                            endAdornment={<InputAdornment position="end">€</InputAdornment>}
                            aria-describedby="outlined-weight-helper-text"
                            label="Min."
                            onChange={(event) => {handlePriceChange(event, 0)}}
                        />
                    </FormControl>
                    <FormControl sx={{ m: 1, width: '100%' }} variant="outlined">
                        <InputLabel htmlFor="outlined-adornment-price-max">Max.</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-price-max"
                            type={'text'}
                            endAdornment={<InputAdornment position="end">€</InputAdornment>}
                            aria-describedby="outlined-weight-helper-text"
                            label="Max."
                            onChange={(event) => {handlePriceChange(event, 1)}}
                        />
                    </FormControl>
                </Box>
            </Box>

            <Box className="filter-year">
                <Typography variant='h6'>Year</Typography>
                <Box>
                    <Box>
                        <FormGroup className='filter-year-wrap'>
                            {
                                years.slice(0, Math.ceil(years.length/2)).map(y => {
                                    return (
                                        <FormControlLabel
                                            control={
                                                <Checkbox checked={year.includes(y)} onChange={handleYearChange} name={y.toString()} />
                                            }
                                            label={y}
                                            key={y}
                                        />
                                    )
                                })
                            }
                        </FormGroup>
                    </Box>
                    <Box>
                        <FormGroup className='filter-year-wrap'>
                            {
                                years.slice(Math.ceil(years.length/2)).map(y => {
                                    return (
                                        <FormControlLabel
                                            control={
                                                <Checkbox checked={year.includes(y)} onChange={handleYearChange} name={y.toString()} />
                                            }
                                            label={y}
                                            key={y}
                                        />
                                    )
                                })
                            }
                        </FormGroup>
                    </Box>
                </Box>
            </Box>

            <Box className="filter-seller">
                <Typography variant='h6'>Seller</Typography>
                <Box>
                    <Box>
                        <FormGroup>
                            <FormControlLabel 
                                control={
                                    <Checkbox 
                                        checked={seller.includes("Particulier")} 
                                        onChange={handleSellerChange} 
                                        name={"Particulier"} 
                                        sx={{
                                            [`&, &.${checkboxClasses.checked}`]: {
                                                color: 'var(--black)',
                                            },
                                        }}
                                        />
                                } 
                                label="Particular" 
                                />
                        </FormGroup>
                    </Box>
                    <Box>
                        <FormGroup>
                            <FormControlLabel control={<Checkbox checked={seller.includes("Professionnel")} onChange={handleSellerChange} name={"Professionnel"} />} label="Pro" />
                        </FormGroup>
                    </Box>
                </Box>
            </Box>

            <Ad adFormat='auto' adSlot={"8142528879"} />

            {/*<FormGroup>
                <FormControlLabel control={<Switch checked={sold} onChange={(e) => setSold(e.target.checked)} />} label="Uniquement non vendues" />
            </FormGroup>*/}

            {/* Sélecteur de catégorie */}
            {/*<FormControl fullWidth margin="normal">
                <InputLabel id="category-label">Catégorie</InputLabel>
                <Select
                labelId="category-label"
                id="categorySelect"
                value={category}
                label="Catégorie"
                onChange={handleCategoryChange}
                >
                <MenuItem key={"All categories"} value={"all"}>All categories</MenuItem>
                {
                    categories.map(category => {
                        return (
                            <MenuItem key={category} value={category}>{category}</MenuItem>
                        )
                    })
                }
                </Select>
            </FormControl>*/}

            {/* Sélecteur de localisation */}
            {/*<FormControl fullWidth margin="normal">
                <InputLabel id="location-label">Localisation</InputLabel>
                <Select
                labelId="location-label"
                id="locationSelect"
                value={location}
                label="Localisation"
                onChange={handleLocationChange}
                >
                <MenuItem key={"All location"} value={"all"}>All location</MenuItem>
                {
                    locations.map(location => {
                        return (
                            <MenuItem key={location} value={location}>{location}</MenuItem>
                        )
                    })
                }
                </Select>
            </FormControl>*/}

            {/* Sélecteur de classe */}
            {/*<FormControl fullWidth margin="normal">
                <InputLabel id="class-label">Classe</InputLabel>
                <Select
                labelId="class-label"
                id="classSelect"
                value={carClass}
                label="Classe"
                onChange={handleClassChange}
                >
                    <MenuItem value="all-class">Toutes les classes</MenuItem>
                    <MenuItem value="class-sup-0">Offres intéressantes</MenuItem>
                    <MenuItem value="class-sup-3">Meilleures offres</MenuItem>
                </Select>
            </FormControl>*/}
        </Box>
    );
};
